import * as Sentry from '@sentry/nuxt'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('vue:error', (_error, _instance, _info) => {
    useLogger().debug(`showing sentry error report dialog`)
    Sentry.showReportDialog({
      /* optional options */
      showBranding: false,
    //   user: {
    //     email: useAuthUserEmail(),
    //     name: useAuthUserName(),
    //   },
    })
  })
})
