import type { Pinia } from 'pinia'
import { createSentryPiniaPlugin } from '@sentry/vue'

// Adds Sentry support to Pinia
export default defineNuxtPlugin(() => {
  const pinia = usePinia() as Pinia

  // Add the Sentry plugin to Pinia
  pinia.use(createSentryPiniaPlugin())
})
