<script lang="ts" setup>
import { useRoute } from 'vue-router'

const devToolVisible = useState('devToolVisible', () => false)
const route = useRoute()

onMounted(() => {
    const devToolParam = route.query.devtool
    devToolVisible.value = devToolParam === 'true'
})

const handleClose = () => {
    devToolVisible.value = false
}
</script>

<template>
  <div v-if="devToolVisible" class="fixed bottom-5 right-5 z-50">
    <DevDebugBar @close="handleClose" />
  </div>
</template>
