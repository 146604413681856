// Disable debug logging for posthog client
export const disablePosthogDebugInDev = async () => {
  const posthog = await usePosthog()
  useLogger().debug('Disabling posthog debug in dev', '#posthog')
  if (posthog && import.meta.env.MODE === 'development' && useRuntimeConfig().public?.posthog?.disableDebugInDev) {
    posthog.debug(false)
    useLogger().debug('Posthog debug disabled', '#posthog')
  }
  else {
    useLogger().debug('Posthog debug not disabled - not in dev or not set', '#posthog')
  }
}

export const usePosthogLoadToolbar = async () => {
  const posthog = await usePosthog()
  posthog?.loadToolbar({
    // Note this may not work when using a custom api_host.
  })
}
