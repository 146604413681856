import type { PostHog } from 'posthog-js'

// Wrapper to access the client and server posthog instances depending on the environment.
export default async function (): Promise<PostHog | undefined> {
  const clientPosthog = useNuxtApp().$clientPosthog as PostHog
  const serverPosthog = useNuxtApp().$serverPosthog as PostHog
  if (import.meta.server) {
    return serverPosthog
  }

  if (clientPosthog) {
    return clientPosthog
  }
  else {
    useLogger().debug('Posthog is not available', '#posthog')
    return void 0
  }
}
