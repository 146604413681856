<script setup lang="ts">
import type { NuxtError } from '#app'

const error = useError() as Ref<NuxtError | null>

const errorMessage = computed(() => {
  if (!error.value) { return 'An unexpected error occurred' }

  // Handle fatal errors differently
  if (error.value.fatal) {
    return 'A fatal error occurred and the page cannot continue'
  }

  return error.value.message || error.value.statusMessage || 'An unexpected error occurred'
})

const statusCode = computed(() => {
  if (!error.value) { return 500 }
  return error.value.statusCode || 500
})

const errorType = computed(() => {
  if (!error.value) {
    return 'Unknown'
  }
  if (error.value.unhandled) {
    return 'Unhandled'
  }
  if (error.value.fatal) {
    return 'Fatal'
  }
  return 'Error'
})

const errorDetails = computed(() => {
  if (!error.value) {
    return {
      stack: '',
      statusMessage: '',
      data: {},
      cause: undefined,
    }
  }

  return {
    stack: error.value.stack || '',
    statusMessage: error.value.statusMessage || '',
    data: error.value.data || {},
    cause: error.value.cause,
  }
})

const friendlyErrorDescription = computed(() => {
  const descriptions: Record<number, string> = {
    400: 'The request was malformed or contains invalid parameters.',
    401: 'You need to be logged in to access this resource.',
    403: 'You don\'t have permission to access this resource.',
    404: 'The page you\'re looking for doesn\'t exist or has been moved.',
    408: 'The request took too long to complete.',
    429: 'Too many requests. Please try again later.',
    500: 'Our server encountered an unexpected error.',
    502: 'We\'re having trouble connecting to our backend services.',
    503: 'The service is temporarily unavailable. Please try again later.',
    504: 'The server gateway timed out.',
  }

  // If it's a fatal error, provide more context
  if (error.value?.fatal) {
    return 'A critical error occurred that prevents the application from continuing. Please try refreshing the page.'
  }

  // If it's an unhandled error, provide appropriate message
  if (error.value?.unhandled) {
    return 'An unexpected error occurred that wasn\'t properly handled. Our team has been notified.'
  }

  return descriptions[statusCode.value] || 'An unexpected error occurred'
})

const handleError = () => {
  clearError({ redirect: '/' })
}

const showDetails = ref(false)
const glitchText = ref(statusCode.value.toString())
const glitchInterval = ref<NodeJS.Timeout>()

const startGlitchEffect = () => {
  const originalText = statusCode.value.toString()
  const chars = '0123456789'

  glitchInterval.value = setInterval(() => {
    if (Math.random() < 0.95) {
      return
    }

    const pos = Math.floor(Math.random() * originalText.length)
    const char = chars[Math.floor(Math.random() * chars.length)]

    glitchText.value
        = originalText.substring(0, pos)
          + char
        + originalText.substring(pos + 1)

    // Reset after a short delay
    setTimeout(() => {
      glitchText.value = originalText
    }, 50)
  }, 100)
}

onMounted(() => {
  if (import.meta.client) {
    startGlitchEffect()
  }
})

onUnmounted(() => {
  if (glitchInterval.value) {
    clearInterval(glitchInterval.value)
  }
})

const handleReload = () => {
  if (import.meta.client) {
    window.location.reload()
  }
}

const userAgent = computed(() => {
  if (import.meta.client) {
    return window.navigator.userAgent
  }
  return 'Server Side'
})

const colorMode = useColorMode()

const toggleColorMode = () => {
  colorMode.preference = colorMode.value === 'dark' ? 'light' : 'dark'
}

const config = useRuntimeConfig()
const versionInfo = computed(() => ({
  version: config.public.release || 'dev',
  environment: config.public.environment || 'development',
  }))
</script>

<template>
  <div class="min-h-screen relative flex items-center justify-center overflow-hidden">
    <!-- Grid pattern background -->
    <div class="absolute inset-0 pointer-events-none z-[1] grid-pattern opacity-[0.15]" />

    <!-- Background SVG with gentle pulse effect -->
    <div class="absolute inset-0 pointer-events-none z-[2]">
      <img
        src="~/assets/backgrounds/bg-underlay-circ.svg" alt=""
        class="absolute right-0 top-0 h-full w-auto object-cover opacity-10 gentle-pulse"
        aria-hidden="true"
      >
    </div>

    <!-- Move clock to top center -->
    <div class="fixed top-4 left-1/2 -translate-x-1/2 z-50 flex items-center justify-center gap-2 text-sm text-muted-foreground bg-background/80 px-3 py-1.5 rounded-full border border-border backdrop-blur-sm">
      <Icon name="lucide:clock" class="w-4 h-4" />
      <span>{{ new Date().toLocaleTimeString() }}</span>
    </div>

    <!-- Error content needs higher z-index -->
    <div class="relative z-[3] text-center px-4 max-w-2xl w-full">
      <!-- Enhanced error label -->
      <div class="flex items-center justify-center gap-2 mb-6">
        <span
          class="text-md font-semibold uppercase tracking-wider"
          :class="{
            'text-destructive': error?.fatal,
            'text-warning': error?.unhandled,
            'text-foreground': !error?.fatal && !error?.unhandled,
          }"
        >
          {{ errorType }}
        </span>
      </div>

      <h1
        class="text-8xl font-bold mb-4 glitch-text"
        :data-text="glitchText"
      >
        {{ glitchText }}
      </h1>

      <div class="mb-8 space-y-4">
        <p class="text-xl font-medium text-primary">
          {{ errorMessage }}
        </p>

        <p class="text-lg text-muted-foreground">
          {{ friendlyErrorDescription }}
        </p>

        <Button variant="link" size="sm" class="mb-4" @click="showDetails = !showDetails">
          <Icon
            :name="showDetails ? 'lucide:chevron-up' : 'lucide:chevron-down'"
            class="w-4 h-4 mr-2"
          />
          {{ showDetails ? 'Hide' : 'Show' }} Technical Details
        </Button>

        <div
          v-if="showDetails"
          class="text-left p-4 bg-muted rounded-lg border border-border transition-all duration-200"
        >
          <div class="flex items-center gap-2 mb-4">
            <Icon name="lucide:bug" class="w-4 h-4" />
            <span class="font-medium">Debug Information</span>
          </div>

          <div v-if="errorDetails.stack" class="mt-4">
            <div class="text-sm font-medium mb-2">
              Stack Trace:
            </div>
            <div class="max-h-[200px] overflow-y-auto rounded border border-border">
              <pre class="whitespace-pre-wrap break-words text-sm bg-background/50 p-3 font-mono"><code>{{ errorDetails.stack }}</code></pre>
            </div>
          </div>

          <div v-if="errorDetails.statusMessage" class="mt-4 text-sm">
            <strong>Status:</strong> {{ errorDetails.statusMessage }}
          </div>

          <div v-if="Object.keys(errorDetails.data).length" class="mt-4">
            <div class="text-sm font-medium mb-2">
              Additional Data:
            </div>
            <div class="max-h-[150px] overflow-y-auto rounded border border-border">
              <pre class="whitespace-pre-wrap break-words text-sm bg-background/50 p-3 font-mono">{{ errorDetails.data }}</pre>
            </div>
          </div>

          <div v-if="errorDetails.cause" class="mt-4">
            <div class="text-sm font-medium mb-2">
              Error Cause:
            </div>
            <div class="max-h-[150px] overflow-y-auto rounded border border-border">
              <pre class="whitespace-pre-wrap break-words text-sm bg-background/50 p-3 font-mono">{{ errorDetails.cause }}</pre>
            </div>
          </div>

          <div class="mt-4 text-sm">
            <strong>Error Type:</strong> {{ errorType }}
            <span v-if="error?.fatal" class="text-destructive ml-2">(Fatal)</span>
            <span v-if="error?.unhandled" class="text-warning ml-2">(Unhandled)</span>
          </div>

          <div class="mt-4 text-sm text-muted-foreground">
            <strong>User Agent:</strong> {{ userAgent }}
          </div>
        </div>
      </div>

      <div class="space-x-4">
        <Button class="gap-2" @click="handleError">
          <Icon name="lucide:home" class="w-4 h-4" />
          Back to home
        </Button>
        <Button variant="outline" class="gap-2" @click="handleReload">
          <Icon name="lucide:refresh-cw" class="w-4 h-4" />
          Try again
        </Button>
      </div>
    </div>

    <!-- Color mode toggle -->
    <Button
      variant="ghost"
      size="icon"
      class="fixed bottom-4 right-4 z-50"
      @click="toggleColorMode"
    >
      <Icon
        v-if="colorMode.value === 'dark'"
        name="lucide:sun"
        class="h-5 w-5"
      />
      <Icon
        v-else
        name="lucide:moon"
        class="h-5 w-5"
      />
      <span class="sr-only">Toggle color mode</span>
    </Button>

    <!-- Version info -->
    <div class="fixed bottom-4 left-4 z-50 flex flex-col items-start gap-1 text-xs text-muted-foreground">
      <div class="flex items-center gap-1.5">
        <Icon name="lucide:git-branch" class="w-3.5 h-3.5" />
        <span>{{ versionInfo.version }}</span>
      </div>
      <div class="flex items-center gap-1.5">
        <Icon name="lucide:server" class="w-3.5 h-3.5" />
        <span>{{ versionInfo.environment }}</span>
      </div>
    </div>
  </div>
</template>

<style scoped>
.glitch-text {
  position: relative;
  text-shadow:
    0.05em 0 0 rgba(255, 0, 0, 0.75),
    -0.025em -0.05em 0 rgba(0, 255, 0, 0.75),
    0.025em 0.05em 0 rgba(0, 0, 255, 0.75);
  animation: glitch 500ms infinite;
}

.glitch-text::before,
.glitch-text::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.glitch-text::before {
  animation: glitch 650ms infinite;
  clip-path: polygon(0 0, 100% 0, 100% 45%, 0 45%);
  transform: translate(-0.025em, -0.0125em);
  opacity: 0.75;
}

.glitch-text::after {
  animation: glitch 375ms infinite;
  clip-path: polygon(0 80%, 100% 20%, 100% 100%, 0 100%);
  transform: translate(0.0125em, 0.025em);
  opacity: 0.75;
}

@keyframes glitch {
  0% {
    text-shadow:
      0.05em 0 0 rgba(255, 0, 0, 0.75),
      -0.05em -0.025em 0 rgba(0, 255, 0, 0.75),
      -0.025em 0.05em 0 rgba(0, 0, 255, 0.75);
  }
  14% {
    text-shadow:
      0.05em 0 0 rgba(255, 0, 0, 0.75),
      -0.05em -0.025em 0 rgba(0, 255, 0, 0.75),
      -0.025em 0.05em 0 rgba(0, 0, 255, 0.75);
  }
  15% {
    text-shadow:
      -0.05em -0.025em 0 rgba(255, 0, 0, 0.75),
      0.025em 0.025em 0 rgba(0, 255, 0, 0.75),
      -0.05em -0.05em 0 rgba(0, 0, 255, 0.75);
  }
  49% {
    text-shadow:
      -0.05em -0.025em 0 rgba(255, 0, 0, 0.75),
      0.025em 0.025em 0 rgba(0, 255, 0, 0.75),
      -0.05em -0.05em 0 rgba(0, 0, 255, 0.75);
  }
  50% {
    text-shadow:
      0.025em 0.05em 0 rgba(255, 0, 0, 0.75),
      0.05em 0 0 rgba(0, 255, 0, 0.75),
      0 -0.05em 0 rgba(0, 0, 255, 0.75);
  }
  99% {
    text-shadow:
      0.025em 0.05em 0 rgba(255, 0, 0, 0.75),
      0.05em 0 0 rgba(0, 255, 0, 0.75),
      0 -0.05em 0 rgba(0, 0, 255, 0.75);
  }
  100% {
    text-shadow:
      -0.025em 0 0 rgba(255, 0, 0, 0.75),
      -0.025em -0.025em 0 rgba(0, 255, 0, 0.75),
      -0.025em -0.05em 0 rgba(0, 0, 255, 0.75);
  }
}

.gentle-pulse {
  animation: gentlePulse 6s ease-in-out infinite;
}

@keyframes gentlePulse {
  0% {
    opacity: 0.05;
    transform: scale(1);
  }
  50% {
    opacity: 0.12;
    transform: scale(1.01);
  }
  100% {
    opacity: 0.05;
    transform: scale(1);
  }
}

/* Add custom scrollbar styling */
.overflow-y-auto {
  scrollbar-width: thin;
  scrollbar-color: hsl(var(--muted-foreground)) transparent;
}

.overflow-y-auto::-webkit-scrollbar {
  width: 6px;
}

.overflow-y-auto::-webkit-scrollbar-track {
  background: transparent;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
  background-color: hsl(var(--muted-foreground) / 0.3);
  border-radius: 3px;
}

.overflow-y-auto::-webkit-scrollbar-thumb:hover {
  background-color: hsl(var(--muted-foreground) / 0.5);
}

.grid-pattern {
  background-image: linear-gradient(to right, hsl(var(--foreground) / 0.15) 1px, transparent 1px),
    linear-gradient(to bottom, hsl(var(--foreground) / 0.15) 1px, transparent 1px);
  background-size: 16px 16px;
}

/* Add some depth with a radial gradient overlay */
.grid-pattern::after {
  content: '';
  position: absolute;
  inset: 0;
  background: radial-gradient(circle at center, transparent 0%, hsl(var(--background)) 100%);
  opacity: 0.5;
}
</style>
