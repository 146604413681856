<script setup lang="ts">
import { SpeedInsights } from '@vercel/speed-insights/nuxt'
import { ConfigProvider } from 'radix-vue'

const useIdFunction = () => useId()

const titleTemplateDefault = 'Soil Link'
const titleDivider = '|'

const i18nHead = useLocaleHead({
  addSeoAttributes: true,
})

// Initialise Analytics
const { init } = useAnalytics()
// We might want to display a consent banner before initializing analytics
init()

useHead({
  // general seo
  titleTemplate: title =>
    title
      ? `${title} ${titleDivider} ${titleTemplateDefault}`
      : titleTemplateDefault,

  // i18n
  htmlAttrs: {
    lang: i18nHead.value.htmlAttrs!.lang,
  },
  link: [...(i18nHead.value.link || [])],
  meta: [...(i18nHead.value.meta || [])],
})

// Disable debug logging for posthog client in development
onMounted(async () => {
  await disablePosthogDebugInDev()
})
</script>

<template>
  <NuxtLoadingIndicator color="#2dc4b6" />
  <ConfigProvider :use-id="useIdFunction">
    <SpeedInsights />
    <ClerkLoaded>
      <ClerkAuthWatcher />
    </ClerkLoaded>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <Toaster />
    <DevToolWrapper />
  </ConfigProvider>
</template>
