import type { OrganizationResource, UserResource } from '@clerk/types'
import * as Sentry from '@sentry/nuxt'

export const useClerkAuthWatcher = () => {
  const clerk = useClerk()
  const { user } = useUser()
  const { organization } = useOrganization()
  const logger = useLogger()

  const sentryUserIsSet = useState('sentryUserIsSet', () => false)
  const posthogUserIsSet = useState('posthogUserIsSet', () => false)
  const previousState = useState('previousAuthState', () => ({
    userId: null as string | null,
    orgId: null as string | null,
    isAuthenticated: false,
  }))

  const setUserIdentities = async (user: UserResource, org?: OrganizationResource | null) => {
    const posthog = await usePosthog()
    const userData = {
      id: user.id,
      email: user.primaryEmailAddress?.emailAddress,
      name: `${user.firstName || ''} ${user.lastName || ''}`.trim(),
      organization_id: org?.id,
    }

    if (posthogUserIsSet.value) {
      // do not set user identity in posthog if it is already set
    }
    else if (posthog) {
      posthog.capture('user_authenticated')
      posthog.identify(userData.id, {
        email: userData.email,
        name: userData.name,
        organization_id: userData.organization_id,
      })
      logger.log(`User identity set in Posthog`, '#posthog')
      posthogUserIsSet.value = true

      // Set the organization id in posthog
      posthog.group('organization', `${organization.value?.slug}`, {
        id: organization.value?.id,
        slug: organization.value?.slug,
        name: organization.value?.name,
      })

      // Record the event
      posthog.capture('user_authenticated')
    }
    else {
      logger.warn('Posthog not available', '#posthog')
    }

    if (sentryUserIsSet.value) {
      // do not set user identity in sentry if it is already set
    }
    else if (Sentry) {
      Sentry.setUser(userData)
      logger.log(`Sentry user set for ${userData.id} (${userData.name})`, '#sentry', '#auth')
      sentryUserIsSet.value = true
    }
    else {
      logger.warn('Sentry not available to set user identity', '#sentry')
    }
  }

  const clearUserIdentities = async () => {
    const posthog = await usePosthog()

    posthogUserIsSet.value = false
    sentryUserIsSet.value = false

    if (posthog) {
      posthog.reset()
      logger.log(`Posthog reset, identity cleared`, '#posthog', '#auth')
    }

    if (Sentry) {
      Sentry.setUser(null)
      logger.log(`Sentry user reset, identity cleared`, '#sentry', '#auth')
    }
  }

  const handleStateChange = async (newState: {
    userId: string | null
    orgId: string | null
    isAuthenticated: boolean
  }) => {
    try {
      // const posthog = await usePosthog()
      const prev = previousState.value

      // Only log if there's an actual change
      if (prev.userId !== newState.userId
        || prev.orgId !== newState.orgId
        || prev.isAuthenticated !== newState.isAuthenticated) {
        logger.debug('Auth state change detected', '#clerk', '#auth', {
          previous: prev,
          new: newState,
        })
      }

      // Handle sign out
      if (!newState.isAuthenticated && prev.isAuthenticated) {
        await clearUserIdentities()
        useEventCapture('logout')
        previousState.value = newState
        return
      }

      // Handle sign in
      if (newState.isAuthenticated && !prev.isAuthenticated) {
        if (!user.value) { throw new Error('User should be available on sign in') }

        setUserIdentities(user.value, organization.value)
        useEventCapture('login', {
          userId: newState.userId,
          organizationId: newState.orgId,
        })
      }

      // Handle organization changes
      if (newState.isAuthenticated && newState.orgId !== prev.orgId) {
        if (!user.value) { throw new Error('User should be available for org change') }

        if (!newState.orgId) {
          // Organization deactivated
          if (prev.orgId) {
            useEventCapture('organization_deactivated', {
              organization_id: prev.orgId,
              user_id: newState.userId,
            })
            // Reset identities to clear org context
            posthogUserIsSet.value = false
            sentryUserIsSet.value = false
          }
        }
        else if (!prev.orgId) {
          // Organization activated
          const org = organization.value
          if (!org) { throw new Error('Organization should be available') }

          useEventCapture('organization_activated', {
            organization_id: org.id,
            organization_name: org.name,
            organization_slug: org.slug,
            user_id: newState.userId,
          })
        }
        else {
          // Organization switched
          const org = organization.value
          if (!org) { throw new Error('Organization should be available') }

          useEventCapture('organization_switched', {
            previous_organization_id: prev.orgId,
            new_organization_id: org.id,
            organization_name: org.name,
            organization_slug: org.slug,
            user_id: newState.userId,
          })
          // Reset identities to update org context
          posthogUserIsSet.value = false
          sentryUserIsSet.value = false
        }

        // Update identities with new org context if needed
        if (newState.orgId && (!posthogUserIsSet.value || !sentryUserIsSet.value)) {
          setUserIdentities(user.value, organization.value)
        }
      }

      previousState.value = newState
    }
    catch (error) {
      logger.error('Error handling auth state change:', error)
    }
  }

  // Initialize auth watcher
  let unsubscribe: (() => void) | undefined

  onUnmounted(() => {
    unsubscribe?.()
  })

  onMounted(async () => {
    try {
      const { isLoaded } = useAuth()
      await until(isLoaded).toBe(true)

      if (!clerk.value) { throw new Error('Clerk failed to initialize') }

      // Set initial state
      const initialState = {
        userId: user.value?.id ?? null,
        orgId: organization.value?.id ?? null,
        isAuthenticated: !!(user.value && clerk.value.session),
      }
      await handleStateChange(initialState)

      // Watch for changes
      unsubscribe = clerk.value.addListener(({ user, session, organization }) => {
        const newState = {
          userId: user?.id ?? null,
          orgId: organization?.id ?? null,
          isAuthenticated: !!(user && session),
        }
        handleStateChange(newState)
      })
    }
    catch (error) {
      logger.error('Failed to initialize clerk auth watcher', '#clerk', '#auth', { error })
    }
  })
}
