<script lang="ts" setup>
const emit = defineEmits(['close'])

const clerk = useClerk()

// State for position and collapse
const isDocked = ref(false)
const isCollapsed = ref(false)
const containerRef = ref<HTMLElement>()
const activeTab = ref<'user' | 'system' | 'network' | 'config'>('user')

// System information
const route = useRoute()
const router = useRouter()
const colorMode = useColorMode()
const runtimeConfig = useRuntimeConfig()

// Network state
const { isOnline } = useNetwork()
const { memory } = useMemory()

// Group related data for better organization
const userData = {
  userId: useActiveUserId(),
  orgId: useActiveOrgId(),
  clerkUserId: useActiveClerkUserId(),
  clerkOrgId: useActiveClerkOrgId(),
  domain: clerk.value?.domain,
  isSatellite: clerk.value?.isSatellite,
  proxyUrl: clerk.value?.proxyUrl,
  frontendApi: clerk.value?.frontendApi,
}

// Update the memory calculation with proper type handling
const formattedMemory = computed(() => {
  const memoryValue = memory.value
  if (typeof memoryValue !== 'number') {
    return 'N/A'
  }
  return `${(memoryValue / (1024 * 1024)).toFixed(2)} MB`
})

const systemInfo = computed(() => ({
  environment: runtimeConfig.public.environment,
  release: runtimeConfig.public.release,
  colorMode: colorMode.preference,
  route: route.path,
  memory: formattedMemory.value,
  online: isOnline.value ? 'Yes' : 'No',
}))

// Add these computed values for bounds checking
const windowSize = useWindowSize()
const debugBarSize = reactive({
  width: 384, // Default width when not collapsed
  height: 384, // Default height when not collapsed
})

// Update when collapsed state changes
watch(isCollapsed, () => {
  debugBarSize.width = isCollapsed.value ? 120 : 384 // Approximate collapsed width
  debugBarSize.height = isCollapsed.value ? 40 : 384
})

// Enhanced draggable functionality
const { x, y, style, isDragging } = useDraggable(containerRef, {
  initialValue: {
    x: windowSize.width.value - debugBarSize.width - 32,
    y: windowSize.height.value - debugBarSize.height - 32,
  },
  preventDefault: true,
  stopPropagation: true,
  pointerTypes: ['mouse', 'touch', 'pen'],
  axis: 'both',
  // Add bounds checking in the move handler
  // onMove: (position) => {
  //   // Ensure the debug bar stays within viewport bounds
  //   position.x = Math.min(
  //     Math.max(0, position.x),
  //     windowSize.width.value - debugBarSize.width,
  //     )
  //   position.y = Math.min(
  //     Math.max(0, position.y),
  //     windowSize.height.value - debugBarSize.height,
  //     )
  // },
  // Disable dragging when docked
  disabled: isDocked,
})

// Add window resize handling
useEventListener(window, 'resize', () => {
  if (isDocked.value) {
    x.value = windowSize.width.value - debugBarSize.width - 16
    y.value = windowSize.height.value - debugBarSize.height - 32
  }
  else {
    // Keep within bounds when window is resized
    x.value = Math.min(x.value, windowSize.width.value - debugBarSize.width)
    y.value = Math.min(y.value, windowSize.height.value - debugBarSize.height)
  }
})

// Handle docking to edge
function toggleDock() {
  isDocked.value = !isDocked.value
  if (isDocked.value) {
    x.value = window.innerWidth - 384 - 16
    y.value = window.innerHeight - (isCollapsed.value ? 40 : 384) - 32
  }
}

// Handle collapse
function toggleCollapse() {
  isCollapsed.value = !isCollapsed.value
  if (isDocked.value) {
    y.value = window.innerHeight - (isCollapsed.value ? 40 : 384) - 32
  }
}

// Copy value to clipboard
async function copyToClipboard(value: string) {
  await navigator.clipboard.writeText(value)
  useLogger().log('Copied to clipboard:', value)
}

// Navigate to route
function _navigateTo(path: string) {
  router.push(path)
}

// Add watch for component mounting/unmounting
onMounted(() => {
  const newQuery = { ...route.query, devtool: 'true' }
  router.replace({ query: newQuery })
})

// Update emit to handle URL cleanup on close
function handleClose() {
  const newQuery = { ...route.query }
  delete newQuery.devtool
  router.replace({ query: newQuery }).then(() => {
    emit('close')
  })
}

// Add appConfig to your imports
const appConfig = useAppConfig()
</script>

<template>
  <div
    ref="containerRef" :style="[
      isDocked ? { right: '16px', bottom: '16px' } : style,
      { userSelect: 'none' },
    ]"
    class="fixed dev-debug-bar bg-card/95 backdrop-blur rounded-lg shadow-lg border border-border transition-all duration-200 touch-none"
    :class="[
      isDocked ? 'right-4' : 'cursor-move',
      isCollapsed ? 'p-2 w-auto' : 'p-4 w-96',
      isDragging ? 'opacity-90' : 'opacity-100',
    ]"
  >
    <div class="flex justify-between items-center" :class="{ 'mb-4': !isCollapsed }">
      <div class="flex items-center gap-2">
        <Icon name="lucide:terminal" class="h-4 w-4 text-primary" />
        <h2 v-if="!isCollapsed" class="text-sm font-semibold">
          Dev Tools
        </h2>
      </div>
      <div class="flex gap-1">
        <AdminHeaderDropdownMenu />
        <Button variant="ghost" size="icon" class="hover:bg-muted h-6 w-6" @click="toggleCollapse">
          <Icon :name="isCollapsed ? 'lucide:maximize-2' : 'lucide:minimize-2'" class="h-3 w-3" />
        </Button>
        <Button variant="ghost" size="icon" class="hover:bg-muted h-6 w-6" @click="toggleDock">
          <Icon :name="isDocked ? 'lucide:unlock' : 'lucide:lock'" class="h-3 w-3" />
        </Button>
        <Button variant="ghost" size="icon" class="hover:bg-muted h-6 w-6" @click="handleClose">
          <Icon name="lucide:x" class="h-3 w-3" />
        </Button>
      </div>
    </div>

    <div v-if="!isCollapsed" class="space-y-4">
      <!-- Tabs -->
      <div class="flex space-x-1 border-b border-border">
        <button
          v-for="tab in ['user', 'system', 'network', 'config']" :key="tab"
          class="px-2.5 py-1.5 text-xs rounded-t-md transition-colors" :class="[
            activeTab === tab
              ? 'bg-muted text-foreground'
              : 'text-muted-foreground hover:text-foreground',
          ]" @click="activeTab = tab as any"
        >
          {{ tab.charAt(0).toUpperCase() + tab.slice(1) }}
        </button>
      </div>

      <!-- User Info Tab -->
      <div v-if="activeTab === 'user'" class="rounded-md bg-muted/50 p-3">
        <h3 class="text-xs font-medium mb-2 text-muted-foreground">
          User Information
        </h3>
        <div class="space-y-1 text-xs max-h-[600px] overflow-auto">
          <div v-for="(value, key) in userData" :key="key" class="flex justify-between items-center group">
            <span class="text-muted-foreground shrink-0">{{ key }}:</span>
            <div class="flex items-center gap-1 min-w-0">
              <span class="font-mono bg-background/50 px-2 rounded truncate">{{ value || 'N/A' }}</span>
              <Button
                v-if="value" variant="ghost" size="icon" class="h-6 w-6 shrink-0"
                @click="copyToClipboard(String(value))"
              >
                <Icon name="lucide:copy" class="h-3 w-3" />
              </Button>
            </div>
          </div>
        </div>
      </div>

      <!-- System Info Tab -->
      <div v-if="activeTab === 'system'" class="rounded-md bg-muted/50 p-3">
        <h3 class="text-xs font-medium mb-2 text-muted-foreground">
          System Information
        </h3>
        <div class="space-y-1 text-xs max-h-[600px] overflow-auto">
          <div v-for="(value, key) in systemInfo" :key="key" class="flex justify-between items-center ">
            <span class="text-muted-foreground">{{ key }}:</span>
            <span class="font-mono bg-background/50 px-2  rounded">{{ value }}</span>
          </div>
        </div>
      </div>

      <!-- Network Tab -->
      <div v-if="activeTab === 'network'" class="rounded-md bg-muted/50 p-3">
        <h3 class="text-xs font-medium mb-2 text-muted-foreground">
          Network Status
        </h3>
        <div class="space-y-2 text-xs">
          <div class="flex justify-between items-center">
            <span class="text-muted-foreground">Status:</span>
            <span
              class="font-mono px-2  rounded" :class="[
                isOnline ? 'bg-success/20 text-success' : 'bg-destructive/20 text-destructive',
              ]"
            >
              {{ isOnline ? 'Online' : 'Offline' }}
            </span>
          </div>
        </div>
      </div>

      <!-- Config Tab -->
      <div v-if="activeTab === 'config'" class="rounded-md bg-muted/50 p-3">
        <div class="flex justify-between items-center mb-2">
          <h3 class="text-xs font-medium text-muted-foreground">
            App Configuration
          </h3>
          <Button
            variant="ghost"
            size="icon"
            class="h-6 w-6 shrink-0"
            @click="copyToClipboard(JSON.stringify(appConfig, null, 2))"
          >
            <Icon name="lucide:copy" class="h-3 w-3" />
          </Button>
        </div>
        <div class="space-y-1 text-xs max-h-[240px] overflow-auto">
          <pre class="font-mono bg-background/50 p-1.5 rounded whitespace-pre-wrap break-all select-text cursor-text text-[11px] leading-tight">{{ JSON.stringify(appConfig, null, 2) }}</pre>
        </div>
      </div>
    </div>
  </div>
</template>
