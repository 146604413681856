import { useToast } from '~/modules/ui/components/toast'

// Returns the active organization ID (Soil Link Org ID)as a vue ref
export function useActiveOrgId() {
  const { organization } = useOrganization()
  return computed(() => organization.value?.publicMetadata?.soil_link_org_id)
}

// Returns the active user ID (Soil Link User ID) as a vue ref
export function useActiveUserId() {
  const { user } = useUser()
  return computed(() => user.value?.externalId)
}

// Returns the active clerk user ID as a vue ref
export function useActiveClerkUserId() {
  const { user } = useUser()
  return computed(() => user.value?.id)
}

// Returns the active clerk organization ID as a vue ref
export function useActiveClerkOrgId() {
  const { organization } = useOrganization()
  return computed(() => organization.value?.id)
}

// Log out function
export const useLogout = async () => {
  // This is the new auth method using Clerk

  // We will keep supabase auth logout here for now for cases where the user is currently logged in via supabase auth in an old version
  const supabase = useSupabaseClient()

  // Clerk user reference
  const { isSignedIn, isLoaded } = useUser()

  // Clerk client for sign out
  const clerk = useClerk()

  // User profile store
  const userProfileStore = useUserProfileStore()

  useLogger().debug('Signing out', '#useAuth/useLogout')

  if (!isLoaded) {
    return useToast().toast({ title: 'You are not logged in, auth is not loaded', variant: 'error' })
  }

  if (!isSignedIn) {
    return useToast().toast({ title: 'You are not logged in', variant: 'error' })
  }

  const { id: signingOutLoaderToastId } = useToast().toast({ title: 'Signing out...', variant: 'loading' })

  // Capture event indicating user intentionally signed out
  useEventCapture('user_signed_out')

  // Clear user profile data from Pinia store and unsubscribe from profile changes
  userProfileStore.clearProfile()
  userProfileStore.unsubscribeFromProfileChanges()

  // Sign out the user from Clerk. This will sign out all sessions.
  await clerk.value?.signOut()

  // Sign out the user from supabase auth
  if (supabase && supabase.auth) {
    await supabase.auth.signOut({ })
  }

  // Dismiss loader
  useToast().dismiss(signingOutLoaderToastId)

  // Show success toast
  useToast().toast({ title: 'Signed out', variant: 'success' })

  // Redirect to home page
  // return navigateTo('/')
}

// Get user's email
export const useAuthUserEmail = () => {
  const { user, isLoaded, isSignedIn } = useUser()
  if (!isLoaded) {
    useLogger().error('Error getting user email, auth is not loaded')
    return undefined
  }

  if (!isSignedIn) {
    useLogger().error('Error getting user email, you are not logged in')
    return undefined
  }

  return user?.value?.primaryEmailAddress?.emailAddress ?? undefined
}

// Get user's name
export const useAuthUserName = () => {
  const { user, isLoaded, isSignedIn } = useUser()
  if (!isLoaded) {
    useLogger().error('Error getting user name, auth is not loaded')
    return undefined
  }

  if (!isSignedIn) {
    useLogger().error('Error getting user name, you are not logged in')
    return undefined
  }

  return `${user?.value?.fullName}`
}

/**
 * Parse the user's name from authentication metadata.
 *
 * This function attempts to extract and parse the user's full name from
 * Supabase authentication metadata. If successful, it returns the parsed name.
 *
 * @returns {object | null} An object containing parsed name parts, or null if parsing fails.
 */
export const useParseUserNameFromAuth = () => {
  const { user, isLoaded, isSignedIn } = useUser()

  if (!isLoaded) {
    return useToast().toast({ title: 'Error getting user name, auth is not loaded', variant: 'error' })
  }

  if (!isSignedIn) {
    return useToast().toast({ title: 'Error getting user name, you are not logged in', variant: 'error' })
  }

  if (!user.value) { return null }

  // Get the user's full name from auth metadata
  // Prefer 'full_name' if available, fallback to 'name', or use null if neither exists
  const fullName = user?.value?.fullName || null

  // If we have a full name, parse it into parts
  if (fullName) {
    return getParsedName(fullName)
  }
  else {
    // Log an error if we couldn't find a valid name to parse
    useLogger().error(`Unable to parse human name from user `)
    return null
  }
}

// Refresh the user's tokens.
export const useAuthRefreshUserTokens = async () => {
  const clerk = useClerk()
  // handleUnauthenticated will refresh the user's tokens
  useLogger().debug('Refreshing user tokens', '#useAuth/useAuthRefreshUserTokens')
  await clerk.value?.handleUnauthenticated()
}
