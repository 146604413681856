<script setup lang="ts">
// const supabase = useSupabaseClient()
const { user } = useUser()
const devToolVisible = useState('devToolVisible', () => false)

const posthog = await usePosthog()
const { isFeatureEnabled } = usePostHogFeatureFlag()

const activeUserId = useActiveUserId()
const activeOrgId = useActiveOrgId()

const refreshTokens = () => {
  useAuthRefreshUserTokens()
}

const throwTestError = () => {
  throw new Error('Test error from AdminHeaderDropdownMenu')
}
</script>

<template>
  <DropdownMenu v-if="user && isFeatureEnabled('app_header_admin_dropdown_menu')">
    <DropdownMenuTrigger as-child>
      <Button variant="ghost" size="icon">
        <Icon name="soilicon:shield-01" class="size-6 text-muted-foreground" />
      </Button>
    </DropdownMenuTrigger>

    <DropdownMenuContent align="end">
      <DropdownMenuLabel>
        <div class="text-xs font-semibold">
          Admin Menu
        </div>

        <!-- <span class="block text-xs font-normal opacity-70">{{
          user?.primaryEmailAddress?.emailAddress
        }}</span>
        <span v-if="organization?.name" class="block text-xs font-semibold opacity-70">{{
          organization?.name || "No organization"
        }}</span> -->
      </DropdownMenuLabel>

      <DropdownMenuSeparator />

      <DropdownMenuItem @click="navigateTo('https://admin.soil.link', { external: true })">
        <Icon name="soilicon:link-external-01" class="mr-2" />
        <span class="">
          Admin Portal
        </span>
      </DropdownMenuItem>

      <DropdownMenuItem @click="navigateTo('https://aichat.soill.ink', { external: true })">
        <Icon name="soilicon:link-external-01" class=" mr-2" />
        <span class="">
          AI Chat
        </span>
      </DropdownMenuItem>

      <DropdownMenuItem @click="navigateTo('https://images.soill.ink', { external: true })">
        <Icon name="soilicon:link-external-01" class=" mr-2" />
        <span class="">
          Image Library
        </span>
      </DropdownMenuItem>

      <DropdownMenuSeparator />

      <!-- Color mode selection -->
      <DropdownMenuSub>
        <DropdownMenuSubTrigger>
          <ColorModeToggleIcon />
          <div class="ml-2">
            Colour Mode
          </div>
        </DropdownMenuSubTrigger>
        <DropdownMenuPortal>
          <DropdownMenuSubContent avoid-collisions>
            <ColorModeToggleRadioGroup />
          </DropdownMenuSubContent>
        </DropdownMenuPortal>
      </DropdownMenuSub>

      <DropdownMenuSeparator />

      <!-- Switch Organization selection -->

      <!-- <DropdownMenuSub>
        <DropdownMenuSubTrigger>
          <Icon name="soilicon:code-01" class="size-4" />
          <div class="ml-2">
            Switch Organisation
          </div>
        </DropdownMenuSubTrigger>
        <DropdownMenuPortal>
          <DropdownMenuSubContent avoid-collisions>
            <OrganizationSwitcherRadioGroup />
          </DropdownMenuSubContent>
        </DropdownMenuPortal>
      </DropdownMenuSub> -->

      <DropdownMenuItem @click="devToolVisible = true">
        <Icon name="soilicon:code-browser" class="size-4 text-muted-foreground mr-2" />
        Dev Tool
      </DropdownMenuItem>

      <DropdownMenuSub>
        <DropdownMenuSubTrigger>
          <Icon name="soilicon:code-01" class="size-4" />
          <div class="ml-2">
            Developer
          </div>
        </DropdownMenuSubTrigger>
        <DropdownMenuPortal>
          <DropdownMenuSubContent avoid-collisions>
            <DropdownMenuItem @click="devToolVisible = true">
              <Icon name="soilicon:code-browser" class="size-4 text-muted-foreground mr-2" />
              Show Dev Tool
            </DropdownMenuItem>
            <DropdownMenuItem @click="disablePosthogDebugInDev()">
              <Icon name="soilicon:code-browser" class="size-4 text-muted-foreground mr-2" />
              Disable Posthog Debug
            </DropdownMenuItem>
            <DropdownMenuItem @click="navigateTo('/dev/devtools')">
              <Icon name="soilicon:code-browser" class="size-4 text-muted-foreground mr-2" />
              /dev/devtools
            </DropdownMenuItem>
            <DropdownMenuItem @click="navigateTo('/dev/user')">
              <Icon name="soilicon:code-browser" class="size-4 text-muted-foreground mr-2" />
              /dev/user
            </DropdownMenuItem>
            <DropdownMenuItem @click="throwTestError()">
              <Icon name="soilicon:announcement-01" class="size-4 text-muted-foreground mr-2" />
              Test Error
            </DropdownMenuItem>
          </DropdownMenuSubContent>
        </DropdownMenuPortal>
      </DropdownMenuSub>

      <DropdownMenuSub>
        <DropdownMenuSubTrigger>
          <Icon name="soilicon:settings-03" class="size-4" />
          <div class="ml-2">
            Posthog
          </div>
        </DropdownMenuSubTrigger>
        <DropdownMenuPortal>
          <DropdownMenuSubContent avoid-collisions>
            <DropdownMenuItem @click="usePosthogLoadToolbar()">
              Load Toolbar
            </DropdownMenuItem>
            <DropdownMenuItem @click="posthog?.reloadFeatureFlags()">
              Reload Feature Flags
            </DropdownMenuItem>
            <DropdownMenuItem @click="posthog?.capture('test_event_dev_toolbar_dropdown')">
              Capture Test Event
            </DropdownMenuItem>
            <DropdownMenuItem @click="posthog?.debug(false)">
              Stop Debug
            </DropdownMenuItem>
            <DropdownMenuItem @click="posthog?.set_config({ debug: false })">
              Stop Debug Config
            </DropdownMenuItem>
          </DropdownMenuSubContent>
        </DropdownMenuPortal>
      </DropdownMenuSub>

      <DropdownMenuSeparator />

      <DropdownMenuLabel>
        <div
          v-if="activeUserId"
          class="text-xs max-w-[100px] overflow-hidden whitespace-nowrap text-ellipsis hover:overflow-visible hover:whitespace-normal"
        >
          U: {{ activeUserId }}
        </div>
        <div
          v-if="activeOrgId"
          class="text-xs max-w-[100px] overflow-hidden whitespace-nowrap text-ellipsis hover:overflow-visible hover:whitespace-normal"
        >
          O: {{ activeOrgId }}
        </div>
        <div
          v-if="!activeUserId"
          class="text-xs max-w-[100px] overflow-hidden whitespace-nowrap text-ellipsis hover:overflow-visible hover:whitespace-normal text-red-500"
        >
          MISSING USER ID
        </div>
        <div
          v-if="!activeOrgId"
          class="text-xs max-w-[100px] overflow-hidden whitespace-nowrap text-ellipsis hover:overflow-visible hover:whitespace-normal text-red-500"
        >
          MISSING ORG ID
        </div>
        <!-- <span class="block text-xs font-normal opacity-70">{{
          user?.primaryEmailAddress?.emailAddress
        }}</span>
        <span v-if="organization?.name" class="block text-xs font-semibold opacity-70">{{
          organization?.name || "No organization"
        }}</span> -->
      </DropdownMenuLabel>

      <DropdownMenuItem @click="refreshTokens()">
        <Icon name="soilicon:refresh-cw-04" class=" mr-2" />
        <span class="">
          Refresh Tokens
        </span>
      </DropdownMenuItem>
    </DropdownMenuContent>
  </DropdownMenu>
</template>
