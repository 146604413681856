// Parses human names from full name to parts.
import { Namefully } from 'namefully'

/**
 * Parses a full name string into a Namefully object.
 *
 * @param {string} fullName - The full name to be parsed.
 * @returns {Namefully | undefined} A Namefully object representing the parsed name, or undefined if parsing fails.
 * @throws {Error} If there's an error during parsing.
 */
export const parseHumanName = (fullName: string): Namefully | undefined => {
  try {
    const parsedName: Namefully = new Namefully(fullName)
    useLogger().debug(`Parsed name ${fullName}`, '#humanNameParser', {
      parsedName,
    })
    return parsedName
  }
  catch (error: unknown) {
    useLogger().error(`Error parsing human name`, error)
    return undefined
  }
}

/**
 * Parses a full name string and returns an object with various name parts.
 *
 * @param {string} fullName - The full name to be parsed.
 * @returns {object | null} An object containing different parts of the name, or null if parsing fails.
 * @property {string} full - The full name.
 * @property {string} first - The first name.
 * @property {string} last - The last name.
 * @property {string} short - A shortened version of the name.
 * @property {string} public - The public representation of the name.
 * @property {string} prefix - The name prefix (e.g., Mr., Mrs., Dr.).
 * @property {string} suffix - The name suffix (e.g., Jr., Sr., III).
 * @property {string} middle - The middle name.
 * @property {Array} initials - The initials of the name.
 * @property {string} initialsStr - The initials of the name in string format.
 */
export const getParsedName = (fullName: string) => {
  if (fullName) {
    const name: Namefully | undefined = parseHumanName(fullName)
    useLogger().debug(`Parsed first name from ${fullName}`, '#useAuth/useAuthUserFirstName', {
      name,
    })

    return {
      full: name?.full,
      first: name?.first,
      last: name?.last,
      short: name?.short,
      public: name?.public,
      prefix: name?.prefix,
      suffix: name?.suffix,
      middle: name?.middle,
      initials: name?.initials(),
      initialsStr: name?.initials().join(''),
    }
  }
  else {
    useLogger().error(`Unable to parse human name from ${fullName}`)
    return null
  }
}
