import { defineStore } from 'pinia'
import { useToast } from '~/modules/ui/components/toast'

export interface OrganizationRole {
  name: string
}

interface Organization {
  id: string
  name: string
  status: 'ACTIVE' | 'INACTIVE' | 'SUSPENDED'
  max_users: number | null
  created_at: string
  updated_at: string
}

export interface OrganizationWithRole {
  organization: Organization
  role: OrganizationRole
}

export const useOrganizationStore = defineStore('userOrganizationRoles', () => {
  const user = useSupabaseUser()
  const _supabase = useSupabaseClient()
  // Initialise the userAppState store which will persist the user's selected org
  const { updateAppState, refreshAppState } = useUserAppStateStore()
  // Refresh app state before 1st use
  refreshAppState()

  // State
  const userOrganizationRoles = ref<OrganizationWithRole[]>([])
  const currentOrganization = ref<Organization | null>(null)
  const currentOrganizationUserRole = ref<OrganizationRole | null>(null)
  const loading = ref(false)
  const error = ref<string | null>(null)

  // Getters
  const orgLoaded = computed(() => !!currentOrganization.value?.id)
  const activeOrgId = computed(() => currentOrganization.value?.id || '')
  const isOrgJoined = computed(() => userOrganizationRoles.value.length > 0)
  const totalOrganizations = computed(() => userOrganizationRoles.value.length)
  const activeOrgRoleName = computed(() => currentOrganizationUserRole?.value?.name || '')
  const activeOrgName = computed(() => currentOrganization?.value?.name || '')

  // Actions

  /**
   * Fetches the organizations associated with the current user and sets the current organization.
   *
   * This function performs the following steps:
   * 1. Initializes the userAppState store to persist the user's selected organization.
   * 2. Checks if a user is logged in.
   * 3. Fetches the user's organizations from the API using useAsyncData.
   * 4. Sets the current organization based on the following priority:
   *    a. Organization stored in the user's app state
   *    b. First organization in the list if no stored organization is found
   * 5. Updates the user's app state with the selected organization.
   *
   * The function handles errors by logging them and throwing an error.
   *
   * @async
   * @function fetchuserOrganizationRoles
   * @throws {Error} If no organizations are found to set or if there's an error fetching organizations.
   */
  async function fetchuserOrganizationRoles() {
    const logger = useLogger()
    // logger.log('Fetching user organizations')
    if (!user.value) {
      logger.log('No user found, aborting fetch')
      return
    }
    loading.value = true
    error.value = null

    try {
      // logger.log('Making API request to fetch organizations')
      const { data: orgsAndRoles, error: fetchError } = await useAsyncData<OrganizationWithRole[]>('userOrganizationRoles', () =>
        $fetch('/api/v2/user/organizations', {
          headers: useRequestHeaders(['cookie']),
        }))

      if (fetchError.value) {
        logger.log('Error fetching organizations:', fetchError.value)
        throw new Error(fetchError.value?.message || 'Failed to fetch organizations')
      }

      userOrganizationRoles.value = orgsAndRoles.value || []
      logger.log('Organizations fetched:', userOrganizationRoles.value)

      if (!currentOrganization.value && userOrganizationRoles.value.length > 0) {
        const userAppStateStore = useUserAppStateStore()
        if (userAppStateStore.currentOrganizationId) {
          logger.log(`Found current organization in user app state: ${userAppStateStore.currentOrganizationId}`, '#appState')
          const orgWithRole = userOrganizationRoles.value.find(o => o.organization.id === userAppStateStore.currentOrganizationId)
          if (orgWithRole) {
            currentOrganization.value = orgWithRole.organization
            currentOrganizationUserRole.value = orgWithRole.role
            logger.log(`Found current organization in backend-persisted app state: ${currentOrganization.value.name} - setting to current org`)
          }
        }
        else if (userOrganizationRoles.value[0]) {
          const firstOrgWithRole = userOrganizationRoles.value[0]
          currentOrganization.value = firstOrgWithRole.organization
          currentOrganizationUserRole.value = firstOrgWithRole.role
          logger.log(`Set active organization to first in user list: ${currentOrganization.value.name}`)
          // Update the user's app state with the new organization
          await updateAppState('ORGANIZATION', currentOrganization.value.id)
        }
        else {
          throw new Error('No organizations found to set.')
        }
      }

      // TODO: Implement organization change subscription
      // logger.log('Subscribing to organization changes');
      // subscribeToOrganizationChanges();
    }
    catch (e: unknown) {
      error.value = e instanceof Error ? e.message : String(e)
      logger.error('Error fetching user organizations:', e)
      throw createError({
        statusCode: 500,
        statusMessage: 'Failed to fetch organizations',
        message: error.value,
      })
    }
    finally {
      loading.value = false
      logger.log('fetchuserOrganizationRoles finished')
    }
  }

  /**
   * Switches the current organization for the user.
   *
   * This function performs the following steps:
   * 1. Sends a request to the server to switch the organization.
   * 2. Updates the local state with the new organization.
   * 3. Updates the user's app state to persist the selected organization.
   *
   * @async
   * @function switchOrganization
   * @param {string} organizationId - The ID of the organization to switch to.
   * @throws {Error} If the server rejects the switch or if the organization is not found in user organizations.
   *
   * Side effects:
   * - Sets loading state during the operation.
   * - Updates error state if an error occurs.
   * - Updates currentOrganization in the store.
   * - Updates the ORGANIZATION in the user's app state.
   * - Displays toast notifications for success or failure.
   * - Logs debug information and errors.
   */
  async function switchOrganization(organizationId: string) {
    const { updateAppState } = useUserAppStateStore()
    const logger = useLogger()
    const toast = useToast()

    const { data, error: fetchError } = await useAsyncData<{ success: boolean }>(
      `switchOrganization-${organizationId}`,
      () => $fetch('/api/v2/user/organizations/switch', {
        method: 'POST',
        body: { organizationId },
      }),
      {
        immediate: true,
      },
    )

    if (fetchError.value) {
      error.value = fetchError.value.message
      logger.error('Error switching organization:', fetchError.value)
      if (import.meta.client) {
        toast.toast({
          title: 'Error Switching Organization',
          variant: 'error',
          description: 'Unable to switch organizations. Please try again later.',
        })
      }
      return
    }

    if (!data.value?.success) {
      error.value = 'Server rejected switching org'
      logger.error('Error switching organization: Server rejected')
      if (import.meta.client) {
        toast.toast({
          title: 'Error Switching Organization',
          variant: 'error',
          description: 'Unable to switch organizations. Please try again later.',
        })
      }
      return
    }

    const newOrgWithRole = userOrganizationRoles.value.find(role => role.organization.id === organizationId)
    if (!newOrgWithRole) {
      error.value = 'Organization not found in user organizations'
      logger.error('Error switching organization: Organization not found')
      if (import.meta.client) {
        toast.toast({
          title: 'Error Switching Organization',
          variant: 'error',
          description: 'Unable to switch organizations. Organization not found.',
        })
      }
      return
    }

    currentOrganization.value = newOrgWithRole.organization
    currentOrganizationUserRole.value = newOrgWithRole.role

    // Update the user's app state with the new organization
    await updateAppState('ORGANIZATION', organizationId)

    logger.log(`Switched to organization: ${newOrgWithRole.organization.name}`)
    if (import.meta.client) {
      toast.toast({
        title: 'Organization Switched',
        variant: 'success',
        description: `Successfully switched to ${newOrgWithRole.organization.name}`,
      })
    }
  }

  /**
   * Automatically accepts organization invites for the user and updates the user's state.
   *
   * This function performs the following steps:
   * 1. Sends a request to the server to auto-join organizations the user has been invited to.
   * 2. Updates the local state with the new organizations.
   * 3. Sets the current organization if not already set.
   * 4. Updates the user's app state to persist the selected organization.
   * 5. Displays a toast notification about the joined organization(s).
   *
   * @async
   * @function acceptOrganizationInvites
   * @returns {Promise<boolean>} Returns true if organizations were successfully joined, false otherwise.
   * @throws {Error} If there's an error during the process of accepting invites.
   *
   * Side effects:
   * - Sets loading state during the operation.
   * - Updates error state if an error occurs.
   * - Updates userOrganizationRoles in the store.
   * - May update currentOrganization in the store.
   * - Updates the ORGANIZATION in the user's app state if a new current organization is set.
   * - Displays toast notifications for success or failure.
   * - Logs errors.
   */
  async function acceptOrganizationInvites() {
    loading.value = true
    error.value = null
    const { updateAppState } = useUserAppStateStore()

    try {
      const { success, organizations } = await $fetch<{ success: boolean, organizations?: Organization[] }>('/api/v2/onboarding/autojoin-orgs', {
        method: 'POST',
        headers: useRequestHeaders(['cookie']),
      })

      if (!success) {
        throw new Error('No data received from server')
      }

      if (success && Array.isArray(organizations) && organizations.length > 0) {
        const newOrganizations: OrganizationWithRole[] = organizations.map(org => ({
          organization: org,
          role: { name: 'Member' }, // Default role, adjust as needed
        }))
        userOrganizationRoles.value = [...userOrganizationRoles.value, ...newOrganizations]

        let newCurrentOrg: OrganizationWithRole | null = null
        if (!currentOrganization.value && newOrganizations.length > 0) {
          const firstOrg = newOrganizations[0]
          if (firstOrg) {
            newCurrentOrg = firstOrg
            currentOrganization.value = newCurrentOrg.organization
            currentOrganizationUserRole.value = newCurrentOrg.role
            await updateAppState('ORGANIZATION', newCurrentOrg.organization.id)
          }
        }

        const toastMessage = newOrganizations.length === 1
          ? `You've been automatically joined to ${newOrganizations[0]?.organization.name ?? 'an organisation'}`
          : `You've been joined to ${newOrganizations.length} organisations.`

        if (import.meta.client) {
          useToast().toast({
            title: `You've Joined ${newOrganizations.length > 1 ? `${newOrganizations.length} Organisations` : 'an Organisation'}`,
            variant: 'success',
            description: toastMessage,
          })
        }

        useLogger().log(`Accepted invites for ${newOrganizations.length} organization(s)`, newCurrentOrg ? `Set current organization to: ${newCurrentOrg.organization.name}` : 'Current organization unchanged')

        return true
      }

      useLogger().log('No organizations joined from invites')
      return false
    }
    catch (e) {
      error.value = e instanceof Error ? e.message : String(e)
      useLogger().error('Error accepting organization invites:', e)

      if (import.meta.client) {
        useToast().toast({
          title: 'Error Joining Organisation',
          variant: 'error',
          description: 'Please try again later.',
        })
      }

      return false
    }
    finally {
      loading.value = false
    }
  }

  // RETURNS
  return {
    orgLoaded,
    currentOrganization,
    currentOrganizationUserRole,
    userOrganizationRoles,
    loading,
    error,
    activeOrgId,
    isOrgJoined,
    totalOrganizations,
    fetchuserOrganizationRoles,
    switchOrganization,
    acceptOrganizationInvites,
    activeOrgRoleName,
    activeOrgName,
  }
}, {
  // persist: piniaPluginPersistedstate.localStorage(), // Persist this store in localStorage. Note this only works on the client side.
})

export type OrganizationStore = ReturnType<typeof useOrganizationStore>

// Enable HMR
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useOrganizationStore, import.meta.hot))
}
