export default defineNuxtPlugin(async (_nuxtApp) => {
  useLogger().debug('Initialising stores...', '#store', '#plugin', '#04-init-stores')

  useLogger().debug('Initialising Organization store', '#store', '#plugin', '#04-init-stores')
  const { refreshAppState } = await useUserAppStateStore()
  await refreshAppState()

  useLogger().debug('Initialising Organization store', '#store', '#plugin', '#04-init-stores')
  const organizationStore = await useOrganizationStore()

  useLogger().debug('Organization store initialised', '#store', '#plugin', '#04-init-stores', {})

  //

  // Only fetch organizations if they haven't been loaded yet
  if (!organizationStore?.userOrganizationRoles) {
    useLogger().debug('No userOrganizationRoles set - Fetching user organizations', '#store', '#plugin', '#04-init-stores')
    await organizationStore?.fetchuserOrganizationRoles()
  }
  else {
    useLogger().debug(`Current organization is ${organizationStore.activeOrgId}`, '#store', '#plugin', '#04-init-stores')
  }
})
