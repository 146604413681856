/* eslint no-console: ["error", { allow: ["warn", "log", "error", "debug"] }] */

// A logging wrapper for client-side logging.

import * as Sentry from '@sentry/vue'

type LogLevel = 'debug' | 'log' | 'info' | 'warn' | 'error'
type Tag = `#${string}`
type LogFunction = (message: string, ...args: (Tag | any)[]) => void

interface LogContext {
  userId?: string
  sessionId: string
  timestamp: string
  environment: string
  version?: string
}

interface RateLimit {
  count: number
  firstCall: number
}

const getTagStyles = (() => {
  const styles: Record<LogLevel, string> = {
    debug: 'background-color: #9C27B0; color: white;',
    log: 'background-color: #4CAF50; color: white;',
    info: 'background-color: #2196F3; color: white;',
    warn: 'background-color: #FFC107; color: black;',
    error: 'background-color: #F44336; color: white;',
  }
  const baseStyles = 'font-weight: bold; padding: 2px 4px; border-radius: 2px;'

  return (level: LogLevel) => `${baseStyles} ${styles[level]}`
})()

const mapLogLevelToSentryLevel = (level: LogLevel): Sentry.SeverityLevel => {
  switch (level) {
    case 'debug':
    case 'log':
    case 'info':
      return 'info'
    case 'warn':
      return 'warning'
    case 'error':
      return 'error'
    default:
      // eslint-disable-next-line no-case-declarations
      const _exhaustiveCheck: never = level
      return _exhaustiveCheck
  }
}

export const useLogger = () => {
  const config = useRuntimeConfig()
  const user = useSupabaseUser()
  const route = useRoute()

  // Generate a session ID when the logger is first initialized
  const sessionId = useState('loggerSessionId', () => crypto.randomUUID())

  const getLogContext = (): LogContext => ({
    userId: user.value?.id,
    sessionId: sessionId.value,
    timestamp: new Date().toISOString(),
    environment: config.public.environment || 'development',
    version: config.public.release,
  })

  const rateLimits = new Map<string, RateLimit>()
  const RATE_LIMIT_WINDOW = 60000 // 1 minute
  const RATE_LIMIT_MAX = 100 // max logs per minute

  const checkRateLimit = (level: LogLevel): boolean => {
    const now = Date.now()
    const key = `${level}-${now}`
    const limit = rateLimits.get(key)

    if (!limit) {
      rateLimits.set(key, { count: 1, firstCall: now })
      return true
    }

    if (now - limit.firstCall > RATE_LIMIT_WINDOW) {
      rateLimits.set(key, { count: 1, firstCall: now })
      return true
    }

    if (limit.count >= RATE_LIMIT_MAX) {
      return false
    }

    limit.count++
    return true
  }

  const createLogFunction = (consoleMethod: LogFunction, level: LogLevel): LogFunction => {
    return (message: string, ...args: (Tag | any)[]) => {
      if (!checkRateLimit(level)) {
        return // Skip if rate limited
      }
      if (config.public.DEBUG || level === 'error') {
        const tags = args.filter((arg): arg is Tag => typeof arg === 'string' && arg.startsWith('#'))
        const otherArgs = args.filter(arg => typeof arg !== 'string' || !arg.startsWith('#'))

        const tagStyles = getTagStyles(level)
        const tagString = tags.map(tag => `%c${tag}%c`).join(' ')
        const styleArgs = tags.flatMap(_tag => [tagStyles, ''])

        consoleMethod(`${tagString} ${message}`, ...styleArgs, ...otherArgs)

        // Add context to Sentry
        if (config.public.SENTRY_ENABLED) {
          const context = getLogContext()
          const sentryLevel = mapLogLevelToSentryLevel(level)
          const errorObject = otherArgs.find(arg => arg instanceof Error)

          const sentryContext = {
            level: sentryLevel,
            tags: {
              ...Object.fromEntries(tags.map(tag => [tag.slice(1), true])),
              route: route.path,
              ...context,
            },
            extra: {
              ...(otherArgs.length ? { args: otherArgs.filter(arg => arg !== errorObject) } : {}),
              context,
            },
          }

          if (errorObject && level === 'error') {
            Sentry.captureException(errorObject, sentryContext)
          }
          else {
            Sentry.captureMessage(message, sentryContext)
          }
        }
      }
    }
  }

  return {
    debug: createLogFunction(console.debug, 'debug'),
    log: createLogFunction(console.log, 'log'),
    info: createLogFunction(console.log, 'info'),
    warn: createLogFunction(console.warn, 'warn'),
    error: createLogFunction(console.error, 'error'),
  }
}

// Example usage:
// const logger = useLogger()
// logger.debug('Detailed information for debugging', '#debug', '#verbose', { someData: 'value' })
// logger.log('User logged in', '#auth', '#userAction', { userId: 123 })
// logger.info('User preferences updated', '#settings', '#userAction', { preferences: {...} })
// logger.warn('API rate limit approaching', '#api', '#performance')
// logger.error('Failed to save data', '#database', '#error', new Error('DB connection failed'))
